<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading || (!manual && !items.length)"
                rounded="sm"
        >
            <template v-if="!manual && !state.loading" #overlay>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          :variant="manual ? 'success' : 'danger'"
                          @click="changeStatus">
                    {{manual ? ' غیر فعال کردن ' : 'فعال کردن'}}
                    حالت دستی
                </b-button>
            </template>
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <div class="m-1 d-flex justify-content-between" dir="rtl">
                <!--<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="manual ? 'success' : 'danger'"
                          @click="changeStatus">
                    {{manual ? ' غیر فعال کردن ' : 'فعال کردن'}}
                    حالت دستی
                </b-button>-->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >
                    <template #cell(orderType)="data">
                        <b-badge
                                pill
                                :variant="'light-'+typeVariant(data.item.orderType)"
                                class="text-capitalize"
                        >
                            {{typeLabel(data.item.orderType)}}
                        </b-badge>
                    </template>

                    <template #cell(marketType)="data">
                        <b-avatar
                                size="22"
                                class="my-50"
                                :src="`/coins/${$marketType2relatedCoin(data.item.marketType)}.png`"
                                :variant="`light-info`"
                        />
                        /
                        <b-avatar
                                size="22"
                                class="my-50"
                                :src="`/coins/${data.item.marketType.includes('TOMAN') ? 'TOMAN' : 'TETHER'}.png`"
                                :variant="`light-info`"
                        />
                        <!--                - {{coinLabel(data.item.from)+' / '+coinLabel(data.item.to)}}-->
                    </template>

                    <template #cell(unitPrice)="data">
                        <span dir="rtl">
                            {{$toLocal(data.item.unitPrice,$decimal[$marketType2sourceCoin(data.item.marketType)]) + ' ' + $coins[$marketType2sourceCoin(data.item.marketType)].code}}
                        </span>
                    </template>

                    <template #cell(totalPrice)="data">
                        <span dir="rtl">
                            {{$toLocal(data.item.unitPrice*data.item.amount,$decimal[$marketType2sourceCoin(data.item.marketType)]) + ' ' + $coins[$marketType2sourceCoin(data.item.marketType)].code}}
                        </span>
                    </template>

                    <template #cell(orderStatus)="data">
                        <b-badge
                                pill
                                :variant="'light-'+orderStatusVariant(data.item.orderStatus)"
                                class="text-capitalize"
                        >
                            {{orderStatusLabel(data.item.orderStatus)}}
                        </b-badge>
                    </template>

                    <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{$G2J(data.item.createdAtDateTime)}}
                        </span>
                    </template>

                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{$toLocal(data.item.amount,$decimal[$M2RC(data.item.marketType)])}}
                        </span>
                    </template>

                    <template #cell(executedAmount)="data">
                        <span dir="ltr">
                            {{$toLocal(data.item.executedAmount,$decimal[$M2RC(data.item.marketType)])}}
                        </span>
                    </template>

                    <template #cell(executedPercent)="data">
                        <b-badge pill
                                 :variant="data.item.executedPercent ? 'light-warning' : 'light-danger'"
                                 class="text-capitalize">
                            {{$toLocal(data.item.executedPercent)}}%
                        </b-badge>
                    </template>

                    <template #cell(relatedCoin)="data">
                        <b-avatar
                                size="25"
                                class="mr-50"
                                :src="`/coins/${data.item.relatedCoin}.png`"
                                variant="light-info"
                        />
                        {{$coins[data.item.relatedCoin].persianName}}
                    </template>

                    <template #cell(action)="{item,index}">
                        <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DELETE_ORDERS')">
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="$havePermission('CREATE_WALLET')"
                                                 @click="doVandarPaymennt(item)">
                                    <feather-icon class="text-success" icon="CheckSquareIcon"/>
                                    <span class="text-success align-middle ml-50">انجام تراکنش</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="cancelRequest(index)">
                                    <feather-icon class="text-danger" icon="XCircleIcon"/>
                                    <span class="text-danger align-middle ml-50">لغو درخواست</span>
                                </b-dropdown-item>

                                <!-- <b-dropdown-item>-->
                                <!--    <feather-icon icon="FileTextIcon"/>-->
                                <!--    <span class="align-middle ml-50">جزییات سفارش</span>-->
                                <!-- </b-dropdown-item>-->
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>

                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BBadge,
        BAvatar,
        // BForm,
        // BFormInput,
        // BFormGroup,
        // BInputGroup,
        // BInputGroupAppend,
        BOverlay,
        // BListGroup,
        // BListGroupItem,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    import NotAllowed from "@/layouts/components/NotAllowed";
    import {marketFilterArray} from "@/libs/globalConstants";
    // import {QRCanvas} from 'qrcanvas-vue';

    export default {
        name: 'ManualOrders',
        components: {
            NotAllowed,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            BDropdown,
            BDropdownItem,
            BBadge,
            BAvatar,
            vSelect,
            // BForm,
            // BFormInput,
            // BFormGroup,
            // BInputGroup,
            // BInputGroupAppend,
            BOverlay,
            // BListGroup,
            // BListGroupItem,
            // QRCanvas,

        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 20,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            label: {},
            manualDefray: {
                txid: '',
                amount: '',
            },
            tomanModal: false,
            vandarModal: false,
            cryptoModal: false,
            isActive: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            passwordType: 'password',
            vandar: {
                mobile: '',
                password: ''
            },
            columns: [
                {
                    label: 'کاربری',
                    key: 'customer',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'نوع سفارش',
                    key: 'orderType',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'خرید', value: 'EXCHANGE_BUY'},
                        {label: 'فروش', value: 'EXCHANGE_SELL'},
                    ]
                },
                {
                    label: 'بازار',
                    key: 'marketType',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: marketFilterArray
                },
                {
                    label: 'مقدار کل',
                    key: 'amount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'مقدار انجام شده',
                    key: 'executedAmount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'درصد انجام شده',
                    key: 'executedPercent',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'قیمت واحد',
                    key: 'unitPrice',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: 'ارزش کل',
                    key: 'totalPrice',
                    sortable: false,
                    // searchType: 'number'
                },
                {
                    label: 'تاریخ ایجاد',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                {
                    label: 'عملیات',
                    key: 'action'
                },
            ],
            walletName: '',
            walletUnit: '',
            request: '',
            selectedWallet: '',
            walletsList: '',
            password: '',
            loading: false,
            wallets: [
                {name: '', totalBalance: 0},
            ],
            options: {
                cellSize: 8,
                correctLevel: 'H',
                data: 'test',
            },
            manual: true,
        }),
        computed: {
            statusVariant() {
                const a = {
                    inactive: 'danger',
                    active: 'success',
                }
                return e => a[e];
            },
            statusLabel() {
                const a = {
                    inactive: 'لغو شده',
                    active: 'انجام شده',
                }
                return e => a[e];
            },
            orderStatusVariant() {
                const a = {
                    FINISHED: 'success',
                    IS_OPEN: 'warning',
                    CANCELLED_BY_USER: 'danger',
                    CANCELLED_BY_ADMIN: 'danger',
                    EXPIRED: 'danger',
                }
                return e => a[e];
            },
            orderStatusLabel() {
                const a = {
                    FINISHED: 'تکمیل شده',
                    IS_OPEN: 'فعال',
                    CANCELLED_BY_USER: 'لغو شده کاربر',
                    CANCELLED_BY_ADMIN: 'لغو شده مدیر',
                    EXPIRED: 'منقضی شده',
                }
                return e => a[e];
            },
            typeVariant() {
                const a = {
                    EXCHANGE_BUY: 'success',
                    EXCHANGE_SELL: 'danger',
                }
                return e => a[e];
            },
            typeLabel() {
                const a = {
                    EXCHANGE_BUY: 'خرید',
                    EXCHANGE_SELL: 'فروش',
                }
                return e => a[e];
            },
        },
        methods: {
            async changeStatus() {
                const text = `آیا از ${this.manual ? 'غیر' : ''}فعال کردن حالت دستی مطمئن هستید؟`
                const res = await this.$swal({
                    icon: this.manual ? 'info' : 'error',
                    title: text,
                    confirmButtonText: 'تایید و تغییر حالت',
                    showCancelButton: true,
                    cancelButtonText: 'خیر',
                    text: '',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-secondary ml-1 text-secondary',
                    },
                })
                if (res.value) {
                    this.manual = !this.manual
                    const text2 = `حالت دستی ${this.manual ? '' : 'غیر'}فعال شد`
                    this.$error(text2, '', 'success')
                }
            },

            async doVandarPaymennt(e) {
                const res = await this.$error('','آیا از تکمیل سفارش مطمئن هستید؟','info','بله','خیر')
                if(res.value){
                    this.state.loading = true
                    const [res,] = await this.$http.post('/exchange/approve-order', e)
                    if(res){
                        this.$error('سفارش با موفقیت تکمیل شد.', '', 'success')
                        this.getData(this.currentPage, this.perPage)
                    }else {
                        this.$error('مشکل ارتباط با سرویس دهنده. لطفا بعد از بررسی علت دوباره تلاش کنید.',)
                    }
                }
            },

            cancelRequest(e) {
                const a = this.items[e]
                const customer = '\n' + 'کاربر : ' + a.customer
                const amount = '\n' + ' مقدار : ' + this.$toLocal(a.amount) + ' ' + this.$coins[this.$M2RC(a.marketType)].persianName
                this.$swal({
                    icon: 'error',
                    title: "آیا از لغو درخواست کاربر مطمئن هستید؟",
                    confirmButtonText: 'تایید و لغو درخواست',
                    showCancelButton: true,
                    cancelButtonText: 'خیر',
                    text: "مشخصات درخواست :" + customer + amount,
                    customClass: {
                        confirmButton: 'btn btn-danger',
                        cancelButton: 'btn btn-primary ml-1',
                    },
                }).then(async (result) => {
                    if (result.value) {
                        this.state.loading = true
                        const address = '/orders/' + this.items[e].id
                        await this.$axios.delete(address)

                        await this.getData(this.currentPage, this.perPage)

                        this.$swal({
                            icon: 'success',
                            title: 'سفارش لغو شد',
                            confirmButtonText: 'تایید',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }
                })
            },

            sort(e) {

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },

            async getData(page = 1, perPage = this.perPage) {
                this.state.loading = true

                const queryParams = {
                    orderStatus: 'FINISHED_WITHOUT_TRANSACTION',
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                const res = await this.$axios('/orders/admin', {params: queryParams})

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData()
        }
    }
</script>
<style lang="scss">
    .vs__selected {
        width: calc(100% - 20px);
    }

    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
